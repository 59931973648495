<div *ngIf="loadSetting" class="pt-5">
  <div align="center" class="load-setting"
    [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">
    <i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
  </div>
</div>
<div *ngIf="!loadSetting">
  <div class="section-header">
    <app-header class="app-header">
    </app-header>
  </div>
  <div class="section-login">
    <div class="row landS1 pt-5"
      [ngStyle]="{'background-image': imageUrl}">
      <div class="container pt-5">
        <div class="row pt-5" id="adjust-section-login">
          <div class="col-12 col-md-12">
            <h2 class="s-xl title-login text-center">Sitio exclusivo para operadores mayoristas</h2>
          <!--  <h2 class="s-xl title-login text-center">Exclusive site for travel agents</h2> Acá   -->
          </div>
        </div>
        <div class="row" id="adjust-section-login">
          <div class="col-12 col-md-1"></div>
          <div class="col-12 col-md-10 s1-login"
          [ngStyle]="{'background-color':  getClientsSettingAppAuth?.clients_setting_global?.color_main_2}">
            <login></login>
          </div>
          <div class="col-12 col-md-1"></div>
          <div class=" d-none d-md-block col-md-1">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row-seccion">
    <div class="section-about-us">
      <div class="row justify-content-center mt-3 mt-md-5" id="adjust-section-about-us">
        <div class=" mx-1" id="card-section-about-us">
          <div class="lc-block py-4 mb-4 mb-xl-0" class="lc-block card card-cover h-100 overflow-hidden text-white" id="card-section-about-us"
          lc-helper="background"
          [ngStyle]="sectionAboutUs?.about?.background_image ? {'background-image': 'url(&quot;' + routeUploadFile + sectionAboutUs.about.background_image + '&quot;)'} : {'background-color': sectionAboutUs?.about?.background_color_1}"> <!--ACa-->
            <div class="lc-block mb-4">
              <div editable="rich">
                <h4 class="tittle-card"
                    [ngStyle]="{'color': sectionAboutUs?.about?.color}"
                >
                  {{sectionAboutUs.about?.title}}
                </h4>
                <p class="tittle-card-sub mt-5 ml-2"
                   [ngStyle]="{'color': sectionAboutUs?.about?.color}"
                >
                  {{sectionAboutUs.about?.title_sub}}
                </p>
              </div>
            </div>
            <div class="lc-block mb-lg-5 mb-2 px-3">
              <div editable="rich">
                <p class="card-context"
                   style="margin-left: 8px;"
                   [ngStyle]="{'color': sectionAboutUs?.about?.color}"
                >
                  {{sectionAboutUs.about?.context}}</p> <!--ACA-->
              </div>
            </div>
          </div>
        </div>
        <div class=" mx-1">
          <div class="lc-block card card-cover h-100 overflow-hidden text-white card-register" id="card-section-about-us"
                lc-helper="background"
                #modalJoinus
                data-toggle="modal"
                data-target="#modalJoinus"
                style="background:url('{{routeUploadFile+sectionAboutUs?.tecnology?.background_image}}')  center / cover no-repeat; background-position: 65% 25%; cursor: pointer"
          >
            <div id="text-card-context">
              <div editable="rich mb-1">
                <h4 class="px-4 title-card-middle"
                    [ngStyle]="{'color': sectionAboutUs?.tecnology?.color}"
                >
                  {{sectionAboutUs.tecnology?.title}}</h4>
              </div>
              <div editable="rich">
                <p class="px-4 text-card-middle"
                style="line-height: 23px;"
                [ngStyle]="{'color': sectionAboutUs?.tecnology.color}"
              >
                {{sectionAboutUs.tecnology?.context}}
               </p>
              </div>
            </div>
          </div>
        </div>
        <div class=" mx-1" id="card-section-about-us"
          [ngStyle]="{'background-color': sectionAboutUs?.fairs?.background_color_1}">
          <div class="lc-block py-5">
            <div class="lc-block mb-4">
              <div editable="rich">
                <h4 class="tittle-card"
                    style="margin-top: -1.4em"
                    [ngStyle]="{'color': sectionAboutUs?.fairs?.color}"
                >
                  {{sectionAboutUs.fairs?.title}}
                </h4>
                <p class="tittle-card-sub mt-5 ml-2"
                   style="margin-right: 70px"
                   [ngStyle]="{'color': sectionAboutUs?.fairs?.color}"
                >
                  {{sectionAboutUs.fairs?.title_sub}}
                </p>
              </div>
            </div>
            <div class="container">
              <ng-container *ngIf="imagesAboutUs.length > 0">
                <!-- Si hay más de 3 imágenes, se acomodan en una grilla de 3 columnas -->
                <ng-container *ngIf="imagesAboutUs.length > 3; then grid else row"></ng-container>
                <ng-template #row>
                  <div class="d-flex justify-content-around pb-5">
                    <!-- <span class="d-flex justify-content-around pb-5"> -->
                      <div class="d-flex flex-column ">
                      <img loading="lazy" class="img-responsive pb-5" src="{{images}}" alt="" width="60px" *ngFor="let images of imagesAboutUs">
                    </div>
                      <div class="d-flex flex-column" style="width:120px;">
                        <div *ngFor="let title of titleContext">
                        <p [ngStyle]="{'color': sectionAboutUs.fairs.color}"
                           class="mb-0">
                            <span style="font-weight: 600;">
                                {{title}}
                            </span>
                        </p>
                            <p style="font-size: 13px; font-weight: 400;"
                               [ngStyle]="{'color': sectionAboutUs.fairs.color}"
                            >Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                          </div>
                      </div>
                    <!-- </span> -->
                  </div>
                </ng-template>
                <ng-template #grid>
                  <div class="row">
                    <!-- <div > -->
                      <img loading="lazy" class="img-responsive" src="{{images}}" alt="" *ngFor="let images of imagesAboutUs">
                    <!-- </div> -->
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!----------------Button gruoup about me----------------------------------->
  <div class="container">
    <div class="flex d-flex flex-wrap justify-content-around mt-3 mt-md-5" id="buttonGroup-about-seccion">
      <div class="col-md-6 col-lg-5 col-xl-3">
        <p>
          <button class="btn btn-card " id="icon-button1" title="icon-button1"
            [ngStyle]="{'background-color': sectionAboutUs?.about?.bottom_color}"> <!--aca-->
          </button>
        </p>
      </div>
      <div class="col-md-6 col-lg-5 col-xl-3">
        <p>
          <button class="btn btn-card " id="icon-button2" title="icon-button2"
            [ngStyle]="{'background-color': sectionAboutUs?.tecnology?.bottom_color}"> <!--aca-->
          </button>
        </p>
      </div>
      <div class="col-md-6 col-lg-5 col-xl-3">
        <p>
          <button class="btn btn-card " id="icon-button3" title="icon_button3"
            [ngStyle]="{'background-color': sectionAboutUs?.fairs?.bottom_color}"> <!--aca-->
          </button>
        </p>
      </div>
    </div>
  </div>
  <div class="section-brands container-fluid">
    <div class="navbar navbar-expand-xg navbar-expand-lg navbar-expand-md align-items-center top-content">
      <div class="col-xg-12 col-lg-12 col-md-12 col-xs-12" style="background-color: #fff">
        <p class="pt-5 title-black"> NUESTROS PRODUCTOS</p>
        <!-- <p class="pt-5 title-black" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_2}"> OUR PARTNERS</p> -->
        <owl-carousel-o *ngIf="images" [options]="customOptions">
          <ng-container *ngFor="let image of images">
            <ng-template carouselSlide [width]="80">
              <img loading="lazy" [src]="image" alt="">
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
  <div class="section-destinity pt-5" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_2}">
    <div class="container pt-5">
      <div class="row pt-4" id="adjust-section-about-us">
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-12 col-12 text-center mb-4">
            <img loading="lazy" #icon [src]="this.routeUploadFile+this.sectionDestinity?.info[0].background_image" id="svg-icon-destinity" alt="icon svg">
          <p class="pt-1 text-center title-number"
            [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}"> {{sectionDestinity?.info[0]?.title}}</p>
          <p class="title-dest">{{sectionDestinity?.info[0]?.title_sub}}</p>
          <p class="text-white sub-title-dest">{{sectionDestinity?.info[0]?.context}} </p>
        </div>
        <div class="col-md-3 col-sm-12 col-12 text-center mb-4">
            <img loading="lazy" #icon [src]="this.routeUploadFile+this.sectionDestinity?.info[1].background_image" id="svg-icon-destinity-hotel" alt="icon svg">
          <p class="pt-1 text-center title-number"
            [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">{{sectionDestinity?.info[1]?.title}}</p>
          <p class="title-dest">{{sectionDestinity?.info[1]?.title_sub}}</p>
          <p class="text-white sub-title-dest">{{sectionDestinity?.info[1]?.context}} </p>
        </div>
        <div class="col-md-3 col-sm-12 col-12 text-center mb-4">
            <img loading="lazy" #icon [src]="this.routeUploadFile+this.sectionDestinity?.info[2].background_image" id="svg-icon-destinity-two" alt="icon svg">
          <p class="pt-1 text-center title-number"
            [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">
            {{sectionDestinity?.info[2]?.title}}</p>
          <p class="title-dest">{{sectionDestinity?.info[2]?.title_sub}}</p>
          <p class="text-white sub-title-dest">{{sectionDestinity?.info[2]?.context}}</p>
        </div>
        <div class="col-md-3 col-sm-12 col-12 text-center mb-4 ">
            <img loading="lazy" #icon [src]="this.routeUploadFile+this.sectionDestinity?.info[3].background_image" id="svg-icon-destinity-two" alt="icon svg">
          <p class="pt-1 text-center title-number"
            [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">{{sectionDestinity?.info[3]?.title}}</p>
          <p class="title-dest">{{sectionDestinity?.info[3]?.title_sub}}</p>
          <p class="text-white sub-title-dest">{{sectionDestinity?.info[3]?.context}} </p>
        </div>
      </div>
    </div>
  </div>
  <div class="section-training">
    <div class="academy py-6 pb-5"
      [ngStyle]="{'background-image': 'url(&quot;' + routeUploadFile+sectionTraining?.background_image + '&quot;)'}">
      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item" *ngFor="let item of sectionTraining.carousel;let index = index;let isFirst = first"
            [ngClass]="{active:isFirst}">
            <div class="layer py-md-5 py-6">
              <div class="container py-lg-5 py-md-3">
                <div class="row academy-grids">
                  <div class="col-lg-12 academy-text-booking">
                    <h3 class="heading mb-4 text-center text-uppercase">{{item.title}}</h3>
                    <div class="form-style-booking academy-btn text-center">
                      <button class="btn-button" style=" width: 12% !important; cursor:default !important"
                        [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">PROXIMAMENTE</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carouseller__left" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carouseller__right" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
    <div class="e-learning container-fluid">
      <div class="row row-seccion-e">
        <div class="col-md-3 col-sm-12 col-12 text-center mb-4 card-e-learning"
        [ngStyle]="{'background-color': sectionTraining.learning?.background_color}">
        <!-- <a href="javascript:void(0);" (click)="onLinkClick()"  class="col-md-3 col-sm-12 col-12 text-center mb-4 card-e-learning"
        [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}"> -->
      <p class="title-e-learning text-left">{{textLearning[0].title}}</p>
      <p class="subtitle-e-learning text-left">{{textLearning[0].title_sub}}</p>
      <p class="subtitle-e-learning-parrafo text-left">{{textLearning[0].context}}</p>
    <!-- </a> -->
        </div>
        <div class="col-md-3 col-sm-12 col-12 text-center mb-4 card-e-learning"
             [ngStyle]="{'background-color': sectionTraining.learning?.background_color}">
          <p class="title-e-learning text-left">{{textLearning[1].title}}</p>
          <p class="subtitle-e-learning text-left lh-sm">{{textLearning[1].title_sub}}</p>
          <p class="subtitle-e-learning-parrafo text-left">{{textLearning[1].context}}</p>
        </div>
        <div class="col-md-3 col-sm-12 col-12 text-center mb-4 card-e-learning"
             [ngStyle]="{'background-color': sectionTraining.learning?.background_color}">
          <p class="title-e-learning text-left">{{textLearning[2].title}}</p>
          <p class="subtitle-e-learning text-left">{{textLearning[2].title_sub}}</p>
          <p class="subtitle-e-learning-parrafo text-left">{{textLearning[2].context}}</p>
        </div>
        <div class="col-md-3 col-sm-12 col-12 text-center mb-4 card-e-learning"
             [ngStyle]="{'background-color': sectionTraining.learning?.background_color}">
          <p class="title-e-learning text-left">{{textLearning[3].title}}</p>
          <p class="subtitle-e-learning text-left">{{textLearning[3].title_sub}}</p>
          <p class="subtitle-e-learning-parrafo text-left last-text-e-learning">{{textLearning[3].context}}</p>
        </div>
      </div>

      <!------------------------------ Button group e-learning --------------------------------->

      <div class="row justify-content-center" id="group-commet-e-learning">
        <div class="col-md-6 col-lg-5 col-xl-3">
          <p>
            <a href="#/articles">
              <img loading="lazy" class="" src="/assets/img/app/icon-coment.svg" />
            </a>
          </p>
        </div>
        <div class="col-md-6 col-lg-5 col-xl-3">
          <p>
            <a href="#/articles">
              <img loading="lazy" class="" src="/assets/img/app/icon-coment.svg" />
            </a>
          </p>
        </div>
        <div class="col-md-6 col-lg-5 col-xl-3">
          <p>
            <a href="#/articles">
              <img loading="lazy" class="" src="/assets/img/app/icon-coment.svg" />
            </a>
          </p>
        </div>
        <div class="col-md-6 col-lg-5 col-xl-3">
          <p>
            <a href="#/articles">
              <img loading="lazy" class="" src="/assets/img/app/icon-coment.svg" />
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="section-register">
    <div class="row-gray"></div>
    <div class="pt-5">
      <p class="pt-5 title-black text-uppercase">Registrate</p>
      <div class="row" id="adjust-section-login">
        <div class="col-md-2 col-sm-12 col-12">
        </div>
        <div class="col-md-8 col-sm-12 col-12 text-center">
          <svg-icon src="../../assets/img/app/mouse.svg" id="icon-mouse"
            [ngStyle]="{'fill':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}"></svg-icon>
          <p class="title-joinus text-center pt-4" style="text-transform: uppercase;">UNIRTE A {{getClientsSettingAppAuth?.name}}</p>
          <p class="container subtitle-joinus text-center">Accedé a la suite tecnológica de servicios turísticos,
            diseñada para brindarte la comodidad de realizar reservas en línea en una amplia variedad de productos,
            tales como: hoteles de calidad, alquiler de autos, excursiones, boletos para eventos inolvidables,
            asistencia personalizada para viajeros, cruceros y emocionantes circuitos. Simplificá tu experiencia de viaje con nuestro
            innovador sistema de reservas en línea.

          </p>
            <button #modalJoinus
                    title="modalJoinus"
                    class="view-more text-center"
                    data-toggle="modal"
                    data-target="#modalJoinus"
                    style="border: none; background: none;"
                    [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">
              Entrá acá para registrarte
            </button>
          <button *ngIf="!buttonSuccess" class="border-0 bg-white" #openModalSuccessJoinUs data-toggle="modal" data-target="#modalRes" style="pointer-events: none;"></button>
        </div>
        <div class="col-md-2 col-sm-12 col-12">
        </div>
      </div>
    </div>
    <div class="modal fade center" id="modalJoinus" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class=" bg-white landS5" id="signup">
            <div class="modal-header">
              <div class="col-12">
                <p class="pt-0 title-black text-title-sm"> REGISTRATE </p>
                <div class="text-center">
                  <svg-icon src="../../assets/img/app/mouse.svg" id="icon-mouse2"
                    [ngStyle]="{'fill':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}"></svg-icon>
                </div>
              </div>
              <button #closeButton type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-12 pb-5">
                <signup class="s1-signup-form darkblue" (successEvent)="successEvent()" (errorEvent)="errorEvent()"></signup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade center" id="modalRes" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
          <div class="d-flex justify-content-center bg-white modalResSignUp">
            <div class="modal-header">
              <p>Datos registrados con éxito</p>
              <button #closeButtonModalSuccessSignUp class="closeButtonModalSuccessSignUp" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
      </div>
    </div>


  </div>
  <div class="section-footer">
    <usb-footer></usb-footer>
  </div>
</div>
