import { HttpClient, HttpHeaders , HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import { setting } from '../../../setting';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()

export class HomeService {
	public apiBTIUrl: string;
	public apiBookingUrl  : string;
  public apiOctopus: string;
	constructor(private _http: HttpClient) {
		this.apiBTIUrl = setting.apiBTIUrl;
		this.apiBookingUrl  = setting.apiBookingUrl
    this.apiOctopus = setting.octopusUrl;
	}

	getClientsSettingAppAuth() {
		let params: HttpParams = new HttpParams();
		params = params.set('name', setting.name);
		return this._http.get(this.apiBTIUrl + 'getClientsSettingAppAuth', { params: params });
	}

  public getCountry(): Observable<any> {
    return this._http.get<any>(`${this.apiOctopus}/utilities/search/countrys`).
    pipe(map(res => res.Countrys));
  }

  public getCountriesData(): Observable<any> {
    return this._http.get<any>(`${this.apiOctopus}/utilities/countries`).
    pipe(map(res => res.Countrys));
  }

  public getDestinationsData(countryId: number): Observable<any> {
    return this._http.get<any>(`${this.apiOctopus}/utilities/destinations/${countryId}`).
    pipe(map(res => res.destinations));
  }

  public getCitiesData(destinationId: number): Observable<any> {
    return this._http.get<any>(`${this.apiOctopus}/utilities/cities/${destinationId}`).
    pipe(map(res => res.cities));
  }
}
