
<div class="row" >

<div *ngIf="load" align="center" style="width:100%; height:100%; position:absolute; left:0px; top:0px; z-index:2; background-color: #ffffff91">
	<br><br><br>
	<!-- <fa name="spinner" size='4x' animation="spin"> </fa> -->
		<br>
		<br>
		<p class="text-loading" style="font-family:'Poppins', sans-serif;font-weight: 600;"> Procesando Registro. Aguarde un momento </p>
</div>



<form class="col-12 col-md-12" [formGroup]="form" (ngSubmit)="onSubmit();" novalidate *ngIf="!load">
  <div class="modal-body row shadow-sm  p-3 mb-5 bg-white rounded box-form">
    <div class="col-12 pt-3">
      <p style="font-size: 18px; font-weight: 600; text-transform: uppercase; text-align:center;"
          [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">exclusivo para propietarios de empresas de turismo</p>
    </div>
	    <div class="col-12 col-md-6 form-item pt-3">
			<div>
				<label class="pl-3" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Nombre de la agencia</label>
				<input
                type="text"
                formControlName="TravelAgencyName"
                [ngClass]="{
                  'is-invalid': form.get('TravelAgencyName').value === null || form.get('TravelAgencyName').value === '',
                  'is-valid':form.get('TravelAgencyName').value !== null || form.get('TravelAgencyName').value !== ''
                }"
                class="form-control text-field"
                required

        >
					<span class=" booking  position-absolute mt-0.5 ml-1" style="font-size: 10px; color: #dc3545 !important" *ngIf="form.controls.TravelAgencyName.touched && form.controls.TravelAgencyName.invalid && (!form.controls.TravelAgencyName.errors?.minlength || !form.controls.TravelAgencyName.errors?.maxlength)">
						Por favor inserte un nombre
					</span>
					<span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px; color: #dc3545 !important" *ngIf="form.controls.TravelAgencyName.errors?.minlength || form.controls.TravelAgencyName.errors?.maxlength">
						El nombre debe tener entre 2-60 carácteres
					</span>
			</div>
	    </div>
	    <div class="col-12 col-md-6 form-item pt-3">
			<div>
				<label class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Sitio web</label>
				<input class="form-control text-field"
                [ngClass]="{
                  'is-invalid': form.get('Website').errors && form.get('Website').errors.errorWebsiteFormat || form.get('Website').value === null || form.get('Website').value === '',
                  'is-valid':form.get('Website').value !== null || form.get('Website').value !== ''
                }"
                type="text"
                (input)="webDetectError($event)"
                name="Website"
                formControlName="Website"
                required
                >
					<span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px;color: #dc3545 !important" *ngIf="form.controls.Website.touched && form.controls.Website.invalid && !form.controls.Website.errors?.pattern">
						Por favor inserte un sitio web
					</span>
					<span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px;color: #dc3545 !important" *ngIf="form.controls.Website.errors?.pattern">
						Inserte una dirección valida
					</span>
			</div>
	    </div>
	    <div class="col-12 col-md-6 form-item pt-2">
			<div>
				<label class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Nombre del propietario</label>
				<input class="form-control text-field"
                formControlName="Contact"
                type="text"
                [ngClass]="{
                  'is-invalid': form.get('Contact').value === null || form.get('Contact').value === '',
                  'is-valid':form.get('Contact').value !== null || form.get('Contact').value !== ''
                }"
                required
                >

					<span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px; color: #dc3545 !important" *ngIf="form.controls.Contact.touched && form.controls.Contact.invalid && (!form.controls.Contact.errors?.minlength || !form.controls.Contact.errors?.maxlength)">
						Por favor inserte un nombre
					</span>
					<span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px; color: #dc3545 !important" *ngIf="form.controls.Contact.errors?.minlength || form.controls.Contact.errors?.maxlength">
						El nombre debe tener entre 2-60 carácteres
					</span>

			</div>
	    </div>
	    <div class="col-12 col-md-6 form-item pt-2">
			<div>
				<label  class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Email de contacto
            <span style=" color: black; text-transform: lowercase;"> - se utilizará para registro</span>
        </label>
				<input class="form-control text-field"
                type="email"
                formControlName="EmailContact"
                [ngClass]="{
                  'is-invalid': form.get('EmailContact').errors && form.get('EmailContact').errors.errorEmailFormat || form.get('EmailContact').value === null || form.get('EmailContact').value === '',
                  'is-valid':form.get('EmailContact').value !== null || form.get('EmailContact').value !== ''
                }"
                (input)="emailInput($event)"
                required
                >
					<span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px; color: #dc3545 !important" *ngIf="form.controls.EmailContact.touched && form.controls.EmailContact.invalid && !form.controls.EmailContact.errors?.pattern">
						Por favor inserte un email
					</span>
					<span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px; color: #dc3545 !important" *ngIf="form.controls.EmailContact.errors?.pattern">
						Inserte un email valido
					</span>
			</div>
	    </div>
	    <div class="col-12 col-md-6 form-item pt-2">
        <div>
          <label  class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">País</label>
          <select
                  class="form-control text-field"
                  [ngClass]="{
                    'is-invalid': form.get('Country').value === null || form.get('Country').value === '',
                    'is-valid':form.get('Country').value !== null || form.get('Country').value !== ''
                  }"
                  formControlName="Country"
                  required
                  (change)="getDestinations($event.target.value)">
            <option disabled> Selecciona un país</option>
            <option *ngFor="let co of countries" value="{{co.country_code}}" style="text-transform: capitalize;" > {{co.country_name}}</option>
          </select>
            <span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px" *ngIf="form.controls.Country.touched && form.controls.Country.invalid ">
              Por favor seleccione un país
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6 form-item pt-2">
        <div>
          <label  class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Región</label>
          <select
                  class="form-control text-field"
                  [ngClass]="{
                    'is-invalid': form.get('Destination').value === null || form.get('Destination').value === '',
                    'is-valid':form.get('Destination').value !== null || form.get('Destination').value !== ''
                  }"
                  formControlName="Destination"
                  required
                  (change)="getCities($event.target.value)">
            <option disabled> Selecciona una región</option>
            <option *ngFor="let de of destinations" value="{{de.destination_id}}" style="text-transform: capitalize;" > {{de.destination_name}}</option>
          </select>
            <span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px" *ngIf="form.controls.Destination.touched && form.controls.Destination.invalid ">
              Por favor seleccione una región
            </span>
        </div>
	    </div>
      <div class="col-12 col-md-6 form-item pt-2">
        <div>
          <label  class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Ciudad</label>
          <select
                  class="form-control text-field"
                  [ngClass]="{
                    'is-invalid': form.get('City').value === null || form.get('City').value === '',
                    'is-valid':form.get('City').value !== null || form.get('City').value !== ''
                  }"
                  formControlName="City"
                  required>
            <option disabled> Selecciona una ciudad</option>
            <option *ngFor="let ci of cities" value="{{ci.city_id}}" style="text-transform: capitalize;" > {{ci.city_name}}</option>
          </select>
            <span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px" *ngIf="form.controls.Country.touched && form.controls.Country.invalid ">
              Por favor seleccione un ciudad
            </span>
        </div>
      </div>
      <div class="col-12 col-md-6 form-item pt-2">
        <div>
          <label class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Nro. telefónico</label>
          <div class="d-flex">
            <div style="width: 100%">
              <select class="form-control text-field"
                      id="PhoneCode"
                      [ngClass]="{
                        'is-invalid': form.get('PhoneCode').value === null || form.get('PhoneCode').value === '',
                        'is-valid':form.get('PhoneCode').value !== null || form.get('PhoneCode').value !== ''
                      }"
                      (change)="codePhone($event.target.value)"
                      formControlName="PhoneCode"
              >
                <option value="null" disabled></option>
                <option *ngFor="let country of countries"
                        [value]="country.phone_code">
                          {{country.country_name}} (+{{country.phone_code}})
                </option>
              </select>
            </div>
            <div style="margin-left: 6px;">
              <input class=" form-control text-field"
                     type="text"
                     [ngClass]="{
                      'is-invalid': form.get('PhoneNumber').value === null || form.get('PhoneNumber').value === '',
                      'is-valid':form.get('PhoneNumber').value !== null || form.get('PhoneNumber').value !== ''
                    }"
                     formControlName="PhoneNumber"
                     required>
            </div>
          </div>
            <span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px; color: #dc3545 !important" *ngIf="form.controls.PhoneNumber.touched && form.controls.PhoneNumber.invalid && !form.controls.PhoneNumber.errors?.pattern">
              Debe insertar un número telefónico
            </span>
            <span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px; color: #dc3545 !important" *ngIf="form.controls.PhoneNumber.errors?.pattern">
              Inserte un número de telefóno valido
            </span>
        </div>
	    </div>
	    <div class="col-12 col-md-6 form-item pt-2">
        <div>
          <label  class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">% de viajes en ARG</label>
          <select class="form-control text-field"
                  formControlName="PercentageTravels"
                  [ngClass]="{
                    'is-invalid': form.get('PercentageTravels').value === null || form.get('PercentageTravels').value === '',
                    'is-valid':form.get('PercentageTravels').value !== null || form.get('PercentageTravels').value !== ''
                  }"
                  required

                  >
            <option value="10-20" > 10% - 20% </option>
            <option value="20-30"> 20% - 30% </option>
            <option value="40-50"> 40% - 50% </option>
            <option value="+50"> + 50% </option>
          </select>
            <span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px; color: #dc3545 !important" *ngIf="form.controls.PercentageTravels.touched && form.controls.PercentageTravels.invalid">
              Debe seleccionar un rango
            </span>
          </div>
	    </div>
	    <div  class="col-12 col-md-6 form-item pt-2">
        <div>
          <label  class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Número de empleados</label>
          <input
                  type="number"
                  class="form-control text-field"
                  formControlName="NumberEmployee"
                  [ngClass]="{
                    'is-invalid': form.get('NumberEmployee').value === null || form.get('NumberEmployee').value === '',
                    'is-valid':form.get('NumberEmployee').value !== null || form.get('NumberEmployee').value !== ''
                  }"
                  required>
            <span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px; color: #dc3545 !important" *ngIf="form.controls.NumberEmployee.touched && form.controls.NumberEmployee.invalid">
              Debe seleccionar un número
            </span>
        </div>
	    </div>
	    <div class="col-12 form-item pt-2 pb-5 mt-4">
          <div>
            <label  class="pl-3"  [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">¿Algún comentario?</label>
            <textarea class="form-control text-field"
                      style="height:150px;"
                      formControlName="Comments"
                      [value]="form.value.Comments"
                      ></textarea>
              <span class="s-sm booking position-absolute mt-0.5 ml-1" style="font-size: 10px" *ngIf=" form.controls.Comments.touched && form.controls.Comments.invalid">
                Su comentario debe contener como máximo 200 carácteres
              </span>
          </div>
      </div>
	  </div>
	  <div class="row p-3 leyend rounded rounded-2">
	  	<div class="col-12 col-md-9 pt-3 pl-3">
			<p class="f-justify" class="font-parrafo-white pr-3">
				Completar todos los campos, nos permitirá gestionar mas rápido la configuración del usuario para acceder al sistema. Para ser parte de la suite tecnológica de Moebius debes ser un Operador Mayorista constituido legalmente en tu país con las habilitaciones correspondiente.  Una vez aceptada la solicitud recibirás un mail con las indicaciones para acceder a la suite tecnológica.
			</p>
		</div>
		<div class="col-12 col-md-3 form-item pt-3 text-center">
			<input type="submit" class="btn-button s-l btn-leyend rounded-pill" value="ENVIAR" style="width: 90% !important;" [disabled]="!form.valid"/>
		</div>
	  </div>

</form>

<button *ngIf="!buttonError" class="border-0 bg-white" #openModalErrorJoinUs data-toggle="modal" data-target="#modalResError"></button>

<div class="modal fade center" id="modalResError" tabindex="-1" role="dialog" aria-hidden="true" style="top: 50%;">
  <div class="modal-dialog" role="document">
      <div class="d-flex justify-content-center bg-white modalResSignUp shadow" style="border: 1px solid rgb(204, 204, 204);">
        <div class="modal-header">
          <p style="font-family:'Poppins', sans-serif;font-weight: 600;padding: 14px; margin: 2px;">Este email ya fue registrado, por favor ingrese otro...</p>
          <button #closeButtonModalErrorSignup class="closeButtonModalErrorSignup" type="button" data-dismiss="modal" aria-label="Close" >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
  </div>
</div>
</div>






