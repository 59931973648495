import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import {HomeService} from './home.service';
import {setting} from '../../../setting';
import { OwlOptions } from 'ngx-owl-carousel-o';
import CssFilterConverter from 'css-filter-converter';
import { SignupComponent } from './signup/signup.component';
import { Subscription } from 'rxjs';

@Component({
	selector: 'usb-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	providers: [HomeService]
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChildren('icon', { read: ElementRef }) icons: QueryList<ElementRef>;
  @ViewChild(SignupComponent) SignupComponent: SignupComponent;
  @ViewChild('modalJoinus') modalJoinus: ElementRef;
  @ViewChild('closeButton') closeButton: ElementRef;
  @ViewChild('openModalSuccessJoinUs') openModalSuccessJoinUs: ElementRef;
  @ViewChild('closeButtonModalSuccessSignUp') closeButtonModalSuccessSignUp: ElementRef;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<img src="../../assets/img/app/arrow-izq-slider.png" />', '<img src="../../assets/img/app/arrow-der-slider.png" />'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      },
      1140: {
        items: 6
      },
      1340: {
        items: 6
      },
      1540: {
        items: 6
      },
      1740: {
        items: 6
      },
      1940: {
        items: 6
      }
    },
    nav: true
  }

	public baseUrl: string;
	public apiBookingUrl: string;
	public domain: string;
	public getClientsSettingAppAuth: any;
	public sectionAboutUs: any;
  public sectionBrands: any;
	public sectionLogin: any;
	public sectionTraining: any;
	public sectionDestinity: any;
	public sectionRegister: any;
	public loadSetting : boolean;
	public routeUploadFile:any;
  public images:any = [];
  public imagesAboutUs: any = [];
  public filter?: any;
  public textLearning: any;
  public titleContext: any;
  public buttonSuccess: boolean = false;
  public imageUrl: string;
  private getClientsSettingAppAuthSubscription: Subscription;


	constructor(
		private _http: HttpClient,
		private _homeService: HomeService,
    private cdRef: ChangeDetectorRef,
    private router: Router
	){
		this.domain = setting.c_domain;
		this.loadSetting = false;
    console.log("setting.apiBookingUrl", setting.apiBookingUrl);
	}
  ngOnDestroy(): void {
    // this.getClientsSettingAppAuthSubscription.unsubscribe();


  }

	ngOnInit() {
		// CARGA CONFIGURACION DEL CLIENTE
		this.loadSetting = true;
		this.routeUploadFile =  setting.routeUploadFile;

		localStorage.removeItem(setting.name);
		setTimeout(() => {
		this.getClientsSettingAppAuthSubscription =	this._homeService.getClientsSettingAppAuth().subscribe(
				(response: any) => {
					localStorage.setItem(setting.name, JSON.stringify(response));
				  this.getClientsSettingAppAuth = JSON.parse((localStorage.getItem(setting.name)));
					this.sectionAboutUs   = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_about_us);
          this.sectionBrands   = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_brands);
				  this.sectionLogin     = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_login);
					this.sectionDestinity  = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_destinity);
					this.sectionTraining  = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_training);
					this.sectionRegister  = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_register);
					this.loadSetting = false;
          this.loadBrandsCarousel();
          this.loadImagesAboutUs();
          this.applyImageFilter();
          this.textLearning = this.sectionTraining?.learning?.text;
          this.titleContext = this.sectionAboutUs?.fairs?.title_context;
          this.imageUrl = this.getBackgroundImageUrl();
				},
				error => {
				}
			);
		}, 1000);
		// FIN  CARGA CONFIGURACION DEL CLIENTE
  }

  private getBackgroundImageUrl(): string {
    return `url("${this.routeUploadFile}${this.sectionLogin?.background_image}")`;
  }

  loadBrandsCarousel(){
    for (let index = 0; index < this.sectionBrands?.brands_carousel.length; index++) {
      this.images[index] = this.routeUploadFile + this.sectionBrands?.brands_carousel[index];
    }
  }

  loadImagesAboutUs() {
    this.imagesAboutUs = [];
    const fairsBackgroundImages = this.sectionAboutUs?.fairs?.background_image;
    if (Array.isArray(fairsBackgroundImages)) {
      this.imagesAboutUs = fairsBackgroundImages.map(image => this.routeUploadFile + image);
    }
  }

  // Aplica un filtro de css al elemento de imagen utilizando el color seteado por cliente.
  applyImageFilter() {
    const color = this.getClientsSettingAppAuth?.clients_setting_global?.color_main_3;
    const tempFilterObj = CssFilterConverter.hexToFilter(color, { sheen: false });

    //Extraemos el literal en String de la conversión
    const tempFilterStr = Object.keys(tempFilterObj).map(key => `${key}(${tempFilterObj[key]})`).join(" ");

    // Convierte el color a un objeto de filtro de CSS y extrae los filtros de la respuesta color del resultado.
    const colorRegex = /color\((.*)\)/;
    const colorMatch = tempFilterStr.match(colorRegex);
    const colorFilter = colorMatch ? colorMatch[1] : "";

    this.filter = colorFilter;

    // Establece el atributo de estilo "filter" en el elemento de imagen con los filtros de color extraídos.
    setTimeout(() => {
      this.icons.forEach(icon => {
        icon.nativeElement.setAttribute('style', `filter: ${this.filter}`);
      });
    }, 100);
  }

  onLinkClick() {
    this.router.navigate(['/articles']).then(() => {
      window.scrollTo(0, 0);
    });
  }

  successEvent() {
    this.closeButton.nativeElement.click();

    if(!this.buttonSuccess) {
      this.buttonSuccess = true;
      this.openModalSuccessJoinUs.nativeElement.click();

      setTimeout(() => {
        this.closeButtonModalSuccessSignUp.nativeElement.click();
      }, 3000);
    }
    this.buttonSuccess = false;

  }

  errorEvent() {
    setTimeout(() => {
      this.modalJoinus.nativeElement.click();
    }, 500);

  }

}


